
import {
    Box,
    Flex,
    Grid, SimpleGrid, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import Globe from "components/Globe/Globe";
// Custom icons
import {
    CartIcon,
    DocumentIcon,
    GlobeIcon, WalletIcon
} from "components/Icons/Icons.js";
import React, { useState } from "react";
import { salesData } from "variables/general";
import MiniStatistics from "./components/MiniStatistics";
import SalesByCountry from "./components/SalesByCountry";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import ActiveUsers from "./components/ActiveUsers";
import SalesOverview from "./components/SalesOverview";
import LineChart from "components/Charts/LineChart";
import Sessions from "components/Sessions";



export default function Default() {
  
  const history = useHistory();
  const [state, setState] = useState({
    access_count: 0,
    events_count: 0,
    plan_count: 0,
    scrape_count: 0
  });

  useEffect(() => { 
    let token = localStorage.getItem("token");
    if(token == null) history.push("/auth");
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.inovveme.com/api/admin/", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then((res) =>{
          setState({
            access_count : res.data.acess_count,
            events_count: res.data.events_count,
            scrape_count: res.data.scrape_count,
            plan_count: res.data.plan_count
          });
          console.log(state);
        }).catch((res) =>{
          history.push("/auth");
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const iconTeal = useColorModeValue("red.300", "red.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        Engajame Performance
      </Text>
      <Grid
        templateColumns={{ sm: "4fr 1fr", xl: "1.2fr 1fr" }}
        gap="32px"
        maxW="100%"
        w="100%"
      >
        <Box
          minW="700px"
          h="700px"
          position="absolute"
          right="30px"
          top="14%"
          display={{ sm: "none", md: "block" }}
        >
          <Globe />
        </Box>
        <Stack
          direction="column"
          spacing="24px"
          w="100%"
          mb="24px"
          maxW={{ sm: "315px", md: "100%" }}
          zIndex="0"
        >
          <SimpleGrid columns={{ sm: "1", md: "2" }} spacing="24px">
            <MiniStatistics
              title={"Acessos"}
              amount={state.access_count}
              percentage={1}
              icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            <MiniStatistics
              title={"Planos ativos"}
              amount={state.plan_count}
              percentage={1}
              icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            <MiniStatistics
              title={"Eventos"}
              amount={state.events_count}
              percentage={+14}
              icon={
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              }
            />
            <MiniStatistics
              title={"Usuários"}
              amount={state.scrape_count}
              percentage={8}
              icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
          </SimpleGrid>
          <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        Operadores 
      </Text>
      <SimpleGrid columns={{ sm: "1", md: "2" }} spacing="24px">
            <MiniStatistics
              title={"Eventos"}
              amount={state.access_count}
              percentage={1}
              icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            <MiniStatistics
              title={"Taxa"}
              amount={state.plan_count}
              percentage={1}
              icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
          </SimpleGrid>
          <Sessions/>
          <SalesByCountry
            title={"Acessos Por Canal"}
            labels={["Canal", "Acessos"]}
            salesData={salesData}
          />
        </Stack>
      </Grid>
      
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        gap="24px"
        mb={{ lg: "26px" }}
      >
        
      </Grid>
    </Flex>
  );
}
