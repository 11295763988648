import React, { useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../Executor/executor.css";
import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import productImage1 from "assets/img/profile-image.jpg";
import pix from "assets/img/pix.png";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import { MastercardIcon } from "components/Icons/Icons";
import { HSeparator } from "components/Separator/Separator";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FaHeart, FaComment, FaPaperPlane } from "react-icons/fa";

const Executor = (props) => {
  const [orderData, setOrderData] = useState(null);
  const [eventsData, setEventsData] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgColor = useColorModeValue("#F8F9FA", "gray.800");
  const nameColor = useColorModeValue("gray.500", "white");
  const location = useLocation();
  const [commentValue, setCommentValue] = useState("");
  const [data, setData] = useState({
    id: 1,
    posts_engaging: 1,
    posts: [
      {
        id: 1,
        likes: 10,
        likes_remain: 5,
        comments: [
          {
            id: 1,
            value: "Comentário 1",
            executed: true,
            type: "text",
          },
          {
            id: 2,
            value: "Comentário 2",
            executed: false,
            type: "image",
          },
        ],
      },
      {
        id: 2,
        likes: 5,
        likes_remain: 0,
        comments: [
          {
            id: 1,
            value: "Comentário 1",
            executed: true,
            type: "text",
          },
        ],
      },
    ],
  });

  const postsMock = [
    {
      id: 1,
      likes: 10,
      likes_remain: 5,
      post_image: "",
      comments: [
        {
          id: 1,
          value: "Comentário 1",
          executed: true,
          type: "text",
        },
        {
          id: 2,
          value: "Comentário 2",
          executed: false,
          type: "image",
        },
      ],
    },
    {
      id: 2,
      likes: 5,
      likes_remain: 0,
      post_image: "",
      comments: [
        {
          id: 1,
          value: "Comentário 1",
          executed: true,
          type: "text",
        },
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.inovveme.com/api/admin/engage/post",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        const data = response.data;
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (data.posts.length === 0) return <div></div>;

  const handleAddComment = async (postId) => {
    try {
      const response = await axios.put(
        `http://localhost:8080/api/admin/engage/post/${postId}`,
        {
          type: "COMMENT",
          value: commentValue,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      // Atualizar os dados do post após adicionar o comentário
      const updatedPosts = data.posts.map((post) => {
        if (post.id === postId) {
          return {
            ...post,
            comments: [
              ...post.comments,
              {
                id: post.comments.length + 1,
                value: commentValue,
                executed: false,
                type: "text",
              },
            ],
          };
        }
        return post;
      });
      setData({
        ...data,
        posts: updatedPosts,
      });
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  return (
    <Flex pt={{ sm: "125px", lg: "75px" }} justify="center" mx="auto">
      <Card maxW={{ lg: "800px" }}>
        <CardHeader>
          <Flex direction="column" w="100%">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="22px">
              Engajamento de Post
            </Text>
            <Flex justify="space-between" w="100%">
              <Flex direction="column">
                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                  Posts sendo engajados:{" "}
                  <Text as="span" color="teal.300" fontWeight="bold">
                    {data.posts_engaging}
                  </Text>{" "}
                  <br />
                  <Text as="span" color={textColor} fontWeight="bold"></Text>
                </Text>
                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                  Revisões pendentes:{" "}
                  <Text as="span" color={"teal.300"} fontWeight="bold">
                    0
                  </Text>
                </Text>
              </Flex>
              <Button
                variant="no-hover"
                bg="#fff"
                w="125px"
                h="35px"
                color="gray.700"
                fontSize="xs"
                border="1px solid #E2E8F0"
                fontWeight="bold"
              >
                ATUALIZAR
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody display="flex">
        <Carousel 
        className="carousel"
        showStatus={false} showThumbs={true} showArrows={true} showIndicators={false}>
            {data.posts.map((post) => (
              <Box
                key={post.id}
                borderWidth="1px"
                borderRadius="lg"
                borderColor="gray.200"
                p={4}
                mb={4}
              >
                <Image
                  src={post.post_image}
                  alt="Post Image"
                  mb={4}
                  style={{
                    maxWidth: "300px",
                    height: "auto",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <Flex align="center" mb={2}>
                  <Icon as={FaHeart} color="red" mr={1} />
                  <Text fontWeight="bold" color={textColor}>
                    {post.likes} / {post.likes_remain}
                  </Text>
                </Flex>
                <Flex direction="column">
                  {post.comments.map((comment) => (
                    <Flex key={comment.id} align="center" mb={2}>
                      <Icon
                        as={comment.type === "text" ? FaComment : FaComment}
                        mr={1}
                      />
                      <Text
                        color={comment.executedAt ? textColor : "gray.400"}
                        fontStyle={comment.executedAt ? "normal" : "italic"}
                      >
                        {comment.value}
                      </Text>
                    </Flex>
                  ))}
                  <Flex align="center" mt={2}>
                    <input
                      type="text"
                      placeholder="Adicionar um comentário"
                      style={{
                        border: "1px solid #E2E8F0",
                        borderRadius: "4px",
                        padding: "4px",
                        marginRight: "8px",
                        width: "100%"
                      }}
                      value={commentValue}
                      onChange={(e) => setCommentValue(e.target.value)}
                    />
                    <Button
                      variant="no-hover"
                      bg="#fff"
                      color="gray.700"
                      fontSize="sm"
                      fontWeight="bold"
                      onClick={() =>
                        handleAddComment(post.id)
                      }
                    >
                      <Icon as={FaPaperPlane} />
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Carousel>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default Executor;