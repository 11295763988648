import React, { useEffect, useState } from "react";
import {
    Badge,
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Input,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import { FaEye, FaHeart, FaUsers } from "react-icons/fa";
import { RiScreenshot2Fill } from "react-icons/ri";
import axios from "axios";
import { BsFillSendFill } from "react-icons/bs";
import { formatDistanceToNow } from "date-fns";

const StoryCard = ({ username, profilePic, storyCount, viewCount, storys, onViewStories }) => {
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = useState("");
    const [storyEvents, setStoryEvents] = useState([]);
 
    const handleExpand = () => {
        setExpanded(!expanded);
    };


    function getTimeAgo(executedAt) {
        const timeAgo = formatDistanceToNow(new Date(executedAt), { addSuffix: true });
        return timeAgo;
    }

    function sendViews(storyId, quantity) {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    "https://api.inovveme.com/api/admin/subscription/",
                    {
                        reference_id: storyId,
                        value: quantity,
                        type_event: "VIEW",
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                const event = response.data;
                // Atualiza a lista de eventos do story com o novo evento
                setStoryEvents((prevEvents) => [...prevEvents, event]);
            } catch (error) {
                console.error("Error sending views:", error);
            }
        };

        fetchData();
    }


    useEffect(() => {
        // Atualiza a lista de eventos iniciais para cada story
        const initialEvents = storys.map((story) => ({ id: story.id, events: [] }));
        setStoryEvents(initialEvents);
    }, [storys]);

    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            mb={4}
            width="100%"
            display={expanded ? "table-column" : "flex"}
            justifyContent="space-between"
        >
            <Flex width="100%" justifyContent="space-between">
                <Flex align="center" width="80%" mb={2}>
                    <Image
                        src={profilePic}
                        alt="Profile Picture"
                        boxSize="40px"
                        borderRadius="full"
                        mr={2}
                    />
                    <Text fontWeight="bold">{username}</Text>
                </Flex>
                <Flex align="center"  mb={2}>
                    <Flex >
                        <Icon as={RiScreenshot2Fill} color="gray" mt={1} mr={1} />
                        <Text fontWeight="bold">{storyCount}</Text>
                    </Flex>
                    {/* <Flex width="40%" justify="center">
                        <Icon as={FaUsers} color="gray" mt={1} mr={1} />
                        <Text>{viewCount}</Text>
                    </Flex> */}
                    <Flex justify="flex-end">
                        <Button
                            backgroundColor="#5adde2"
                            size="sm"
                            paddingLeft="20px"
                            marginLeft="20px"
                            leftIcon={<Icon as={FaEye} />}
                            onClick={() => onViewStories(storys, handleExpand)}
                        ></Button>
                    </Flex>
                </Flex>
            </Flex>
            {expanded && (
                <Flex direction="column" mt={4}>
                    {storys.map((story) => (
                        <Box display={"flex"} key={story.id} borderRadius="lg" p={2} mb={2}>
                            <Image src={story.image} alt="Story" maxH="200px" objectFit="cover" />
                            <Box marginLeft={"20px"}>
                                <Text><b> {story.viewsSent}</b> visualizações</Text>
                                <Box id="events" height="109px" marginTop="15px">
                                    {story.events.map((storyEvent, index) => (
                                        <Box key={index}>
                                            {storyEvent.events.map((event, eventIndex) => (
                                                <Box key={eventIndex}>
                                                    <Text fontSize="13px">
                                                        <b>+ {event.value}</b> {getTimeAgo(event.executedAt)}
                                                    </Text>
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>
                                <Flex justifyContent="space-between" marginTop="20px">
                                    <Input value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                        height="30px" />
                                    <Button
                                        marginLeft="10px"
                                        height="30px"
                                        paddingLeft="20px"
                                        leftIcon={<Icon as={BsFillSendFill} />}
                                        onClick={() => {
                                            sendViews(story.id, value);
                                        }} />
                                </Flex>
                            </Box>
                        </Box>
                    ))}
                </Flex>
            )}
        </Box>
    );
};

const StoryList = ({ stories, onViewStories }) => {
    return (
        <Flex direction="column">
            {stories.map((story) => (
                <StoryCard
                    key={story.id}
                    username={story.username}
                    profilePic={story.profile_image}
                    storyCount={story.storys.length}
                    viewCount={story.viewCount}
                    storys={story.storys}
                    onViewStories={onViewStories}
                />
            ))}
        </Flex>
    );
};

export default function StoryComponent() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    "https://api.inovveme.com/api/admin/subscription/storys",
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                const data = response.data;
                setData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const onViewStories = (storys, handle) => {
        console.log("View Stories:", storys);
        // Aqui você pode implementar a lógica para exibir os storys do assinante ao clicar no botão
        handle();
    };

    return (
        <Stack spacing={4} style={{ marginTop: "110px" }}>
            <Text fontWeight="bold" fontSize="lg">
                Storys Engajados
            </Text>
            <StoryList stories={data} onViewStories={onViewStories} />
        </Stack>
    );
}
