import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SearchTable2 from "components/Tables/SearchTable2";
import React from "react";
import { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { columnsData2 } from "variables/columnsData";
import tableData2 from "variables/tableData2.json";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function OrderList() {

  const [tableData, setTableData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.inovveme.com/api/admin/subscription", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then((res) =>{
          const data = res.data;
          const updatedTableData = data.subscription.map(sub => ({
            id: sub.id,
            created: sub.created,
            status: sub.status,
            customer: sub.customer,
            product: sub.product,
            revenue: sub.revenue,
            image: sub.image
          }));
          setTableData(updatedTableData);
        });

      } catch (error) {
        localStorage.clear();
        history.push("/auth");
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
    console.log(tableData);
  }, []);

  if(tableData.length == 0) return <div>Carregando...</div>;

  return (
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        mb="24px"
      >
        <Button
          variant="no-hover"
          bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          w="125px"
          h="35px"
          color="#fff"
          fontSize="xs"
          fontWeight="bold"
          alignSelf={{ sm: "flex-start", lg: null }}
          mb={{ sm: "12px", md: "0px" }}
        >
          ATUALIZAR
        </Button>
        <Stack
          direction="row"
          spacing="10px"
          alignSelf={{ sm: "flex-start", lg: "auto" }}
        >
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<IoIosArrowDown />}
              color="gray.700"
              w="125px"
              h="35px"
              bg="#fff"
              fontSize="xs"
            >
              FILTROS
            </MenuButton>
            <MenuList>
              <MenuItem color="gray.500">Status: Paid</MenuItem>
              <MenuItem color="gray.500">Status: Refunded</MenuItem>
              <MenuItem color="gray.500">Status: Canceled</MenuItem>
              <MenuDivider />
              <MenuItem color="red.300">Remove filter</MenuItem>
            </MenuList>
          </Menu>
          <Button
            variant="no-hover"
            bg="#fff"
            w="125px"
            h="35px"
            color="gray.700"
            fontSize="xs"
            fontWeight="bold"
          >
            EXPORTAR CSV
          </Button>
        </Stack>
      </Flex>
      <Card px="0px">
        <CardBody>
          <SearchTable2 tableData={tableData} columnsData={columnsData2} /> 
        </CardBody>
      </Card>
    </Flex>
  );
}

export default OrderList;
