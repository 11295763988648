/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const columnsData1 = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "POSITION",
    accessor: "position",
  },
  {
    Header: "OFFICE",
    accessor: "office",
  },
  {
    Header: "AGE",
    accessor: "age",
  },
  {
    Header: "START DATE",
    accessor: "created",
  },
  {
    Header: "SALARY",
    accessor: "salary",
  },
];

export const columnsData2 = [
  {
    Header: "PERFIL",
    accessor: "customer",
  },
  {
    Header: "PLANO",
    accessor: "product",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "VALOR",
    accessor: "revenue",
  },
  {
    Header: "CRIADO EM",
    accessor: "created",
  },
  {
    Header: "ID",
    accessor: "id",
  },
];

export const columnsData3 = [
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Evento",
    accessor: "type_event",
  },
  {
    Header: "QTD",
    accessor: "value",
  },
  {
    Header: "Agendado para",
    accessor: "codeScheduled",
  },
];

export const columnsData4 = [
  {
    Header: "Perfil",
    accessor: "image",
  },
  {
    Header: "Usuario",
    accessor: "username",
  },
  {
    Header: "Cadastrado",
    accessor: "registred",
  },
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "Criado em",
    accessor: "created",
  },
];