import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import productImage1 from "assets/img/profile-image.jpg";
import pix from "assets/img/pix.png";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import { MastercardIcon } from "components/Icons/Icons";
import { HSeparator } from "components/Separator/Separator";
import React, { useEffect, useState } from "react";
import { CgShapeCircle } from "react-icons/cg";
import { FaPencilAlt, FaSearch } from "react-icons/fa";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BasicTable from "components/Tables/BasicTable";
import SearchTable2 from "components/Tables/SearchTable1";
import { columnsData3 } from "variables/columnsData";

const OrderDetails = (props) => {

  const [orderData, setOrderData] = useState(null);
  const [eventsData, setEventsData] = useState([]);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#dee2e6", "gray.500");
  const bgColor = useColorModeValue("#F8F9FA", "gray.800");
  const nameColor = useColorModeValue("gray.500", "white"); 
  const location = useLocation();

  const order = location.state.order;


  useEffect(() =>{
    
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.inovveme.com/api/admin/subscription/" + order, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }).then((res) =>{
          setOrderData(res.data);
          const data = res.data;
          console.log(orderData);
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  },[])

  const StatusPayment = ({ statusPay }) => {
    const statusPayFormatted = statusPay.replace(/_/g, ' ');
  
    return (
      <Text color="gray.400" fontSize="sm" fontWeight="normal">
        {statusPayFormatted}
      </Text>
    );
  };

  if(orderData == null) return <div>Carregando...</div>
  return (
    <Flex pt={{ sm: "125px", lg: "75px" }} justify="center" mx="auto">
      <Card maxW={{ lg: "800px" }}>
        <CardHeader>
          <Flex direction="column" w="100%">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="22px">
              Detalhes do plano
            </Text>
            <Flex justify="space-between" w="100%">
              <Flex direction="column">
                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                  Plano número.{" "}
                  <Text as="span" color="teal.300" fontWeight="bold">
                    #{orderData.id}
                  </Text>{" "}
                  criado em  {" "}
                  <Text as="span" color={textColor} fontWeight="bold">
                   {orderData.start_date}
                  </Text>
                </Text>
                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                  Tipo Plano:{" "}
                  <Text as="span" color={textColor} fontWeight="bold">
                    {orderData.plan_type}
                  </Text>
                </Text>
              </Flex>
              <Button
                variant="no-hover"
                bg="#fff"
                w="125px"
                h="35px"
                color="gray.700"
                fontSize="xs"
                border="1px solid #E2E8F0"
                fontWeight="bold"
              >
                ATUALIZAR
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <HSeparator my="22px" />
            <Flex
              direction={{ sm: "column", md: "row" }}
              justify="space-between"
              w="100%"
              align="center"
            >
              <Flex align="center">
                <Box
                  minW="110px"
                  h="110px"
                  me="30px"
                  alignSelf={{ sm: "flex-start", md: "auto" }}
                >
                  <Image
                    src={orderData.profile_image}
                    w="100%"
                    h="100%"
                    borderRadius="100px"
                  />
                </Box>
                <Flex direction="column">
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="6px"
                  >
                    @{orderData.username}
                  </Text>
                  <Text
                    color="gray.400"
                    fontSize="sm"
                    fontWeight="normal"
                    mb="16px"
                  >
                    Seguidores Inicial: {orderData.start_followers}
                  </Text>
                  <Badge
                    colorScheme="green"
                    w="95px"
                    h="28px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    alignSelf={{ sm: "center", md: "auto" }}
                    justifyContent="center"
                    mb={{ sm: "6px", md: "0px" }}
                  >
                    ENGAJANDO
                  </Badge>
                </Flex>
              </Flex>
              <Flex direction="column" align="flex-end">
                <Button
                  variant="no-hover"
                  bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                  w="125px"
                  h="35px"
                  color="#fff"
                  fontSize="xs"
                  fontWeight="bold"
                  mb={{ sm: "12px", md: "36px" }}
                >
                 PAUSAR
                </Button>
                <Text color="gray.400" fontSize="xs" fontWeight="normal">
                  O plano expirará em {orderData.days_left} dias
                </Text>
              </Flex>
            </Flex>
            <HSeparator my="22px" />
            <Grid
              templateColumns={{
                sm: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1.7fr 1fr",
              }}
              gap={{ sm: "24px", lg: "54px" }}
            >
              <Flex direction="column">
                <Text color={textColor} fontWeight="bold" mb="22px">
                  Eventos do Plano
                </Text>
                <Flex
                  alignItems="center"
                  minH="70px"
                  justifyContent="start"
                  //   mb="5px"
                >
                  <Flex direction="column" align="center" h="100%" me="20px">
                    <Icon
                      as={CgShapeCircle}
                      color="teal.300"
                      h={"30px"}
                      w={"30px"}
                      zIndex="1"
                      position="relative"
                    />
                    <Box w="2px" bg="gray.200" h="100%"></Box>
                  </Flex>
                  <Flex direction="column" justifyContent="flex-start" h="100%">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      Novos Seguidores
                    </Text>
                    <Text
                      fontSize="sm"
                      color="gray.400"
                      fontWeight="normal"
                      mb="14px"
                    >
                      {orderData.new_followers}
                    </Text>
                  </Flex>
                </Flex>
                <Flex alignItems="center" minH="70px" justifyContent="start">
                  <Flex direction="column" align="center" h="100%" me="20px">
                    <Icon
                      as={CgShapeCircle}
                      color="teal.300"
                      h={"30px"}
                      w={"30px"}
                      zIndex="1"
                      position="relative"
                    />
                    <Box w="2px" bg="gray.200" h="100%"></Box>
                  </Flex>
                  <Flex direction="column" justifyContent="flex-start" h="100%">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      Posts Engajados
                    </Text>
                    <Text
                      fontSize="sm"
                      color="gray.400"
                      fontWeight="normal"
                      mb="14px"
                    >
                      {orderData.posts_engaged}
                    </Text>
                  </Flex>
                </Flex>
                <Flex alignItems="center" minH="70px" justifyContent="start">
                  <Flex direction="column" align="center" h="100%" me="20px">
                    <Icon
                      as={CgShapeCircle}
                      color="teal.400"
                      h={"30px"}
                      w={"30px"}
                      zIndex="1"
                      position="relative"
                    />
                    <Box w="2px" bg="gray.200" h="100%"></Box>
                  </Flex>
                  <Flex direction="column" justifyContent="flex-start" h="100%">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      Storys Engajados
                    </Text>
                    <Text
                      fontSize="sm"
                      color="gray.400"
                      fontWeight="normal"
                      mb="14px"
                    >
                      {orderData.storys_engaged}
                    </Text>
                  </Flex>
                </Flex>
                <Flex alignItems="center" minH="70px" justifyContent="start">
                  <Flex direction="column" align="center" h="100%" me="20px">
                    <Icon
                      as={CgShapeCircle}
                      color="green.400"
                      h={"30px"}
                      w={"30px"}
                      zIndex="1"
                      position="relative"
                    />
                    <Box w="2px" bg="gray.200" h="100%"></Box>
                  </Flex>
                  <Flex direction="column" justifyContent="flex-start" h="100%">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      Taxa
                    </Text>
                    <Text
                      fontSize="sm"
                      color="gray.400"
                      fontWeight="normal"
                      mb="14px"
                    >
                      {orderData.tax}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column">
                <Flex direction="column" mb="32px">
                  <Text
                    color={textColor}
                    fontSize="md"
                    fontWeight="bold"
                    mb="22px"
                  >
                    Detalhes Pagamento
                  </Text>
                  <Flex
                    p="16px"
                    bg="transparent"
                    borderRadius="15px"
                    width="100%"
                    border="1px solid"
                    borderColor={borderColor}
                    align="center"
                  >
                    <IconBox me="10px" w="25px" h="22px">
                      <Image src={pix}/>
                    </IconBox>
                    <Text color="gray.400" fontSize="sm" fontWeight="normal">
                      <StatusPayment statusPay={orderData.payment_details.status_pay}/> 
                    </Text>
                    <Spacer />
                    <Button
                      p="0px"
                      bg="transparent"
                      w="16px"
                      h="16px"
                      variant="no-hover"
                    >
                      <Icon as={FaSearch} />

                    </Button>
                  </Flex>
                </Flex>
                <Flex direction="column">
                  <Text
                    color={textColor}
                    fontSize="md"
                    fontWeight="bold"
                    mb="22px"
                  >
                    Informações de Cobrança
                  </Text>
                  <Box p="24px" bg={bgColor} borderRadius="12px">
                    <Flex justify="space-between" w="100%">
                      <Flex direction="column">
                        <Text
                          color={nameColor}
                          fontSize="md"
                          fontWeight="bold"
                          mb="10px"
                        >
                          {orderData.user.name}
                        </Text>
                        <Text
                          color="gray.400"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          Telefone:{" "}
                          <Text as="span" color="gray.500" fontWeight="bold">
                          {orderData.user.phone}
                          </Text>
                        </Text>
                        <Text
                          color="gray.400"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          Email:{" "}
                          <Text as="span" color="gray.500" fontWeight="bold">
                          {orderData.user.email}
                          </Text>
                        </Text>
                        <Text
                          color="gray.400"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          Codigo:{" "}
                          <Text as="span" color="gray.500" fontWeight="bold">
                          {orderData.user.id}
                          </Text>
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
              <Flex direction="column" gridColumn={{ md: "1 / 3", lg: "auto" }}>
                <Text
                  color={textColor}
                  fontSize="md"
                  fontWeight="bold"
                  mb="22px"
                >
                  Resumo do Plano
                </Text>
                <Stack direction="column" spacing="12px">
                  <Flex align="center" justify="space-between">
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      Preço do plano:
                    </Text>
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      R$ {orderData.payment_details.amount},00
                    </Text>
                  </Flex>

                  <Flex align="center" justify="space-between">
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      Taxas:
                    </Text>
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      R$ 45,00
                    </Text>
                  </Flex>
                </Stack>
                <Flex align="center" justify="space-between" mt="24px">
                  <Text fontSize="md" color="gray.400" fontWeight="normal">
                    Total:
                  </Text>
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    R$ {orderData.payment_details.amount},00
                  </Text>
                </Flex>
              </Flex>
            </Grid>
          </Flex>
        </CardBody>
        <CardHeader>
          <Flex direction="column" w="100%" mt="70px">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="22px">
              Execução
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <Grid
             
              gap={{ sm: "24px", lg: "54px" }}
            >

              <SearchTable2 tableData={orderData.subscription_events} columnsData={columnsData3}/>
            </Grid>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default OrderDetails;
