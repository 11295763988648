/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import cover from "assets/img/cover-auth.png";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function SignIn() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleLogin = () => {
    const data = {
      username: username,
      password: password
    };

    console.log("chamando api");
    axios.post('https://api.inovveme.com/api/auth/login', data)
      .then(response => {
        // Verificar o status da resposta
        if (response.status === 200) {
          // Login bem-sucedido, redirecionar para a página inicial
          localStorage.setItem("token", response.data.accessToken);
          history.push('/');
        } else {
          // Resposta inesperada, exibir mensagem de erro
          alert('Ocorreu um erro no login.');
        }
      })
      .catch(error => {
        // Verificar o status do erro
        if (error.response && error.response.status === 401) {
          // Credenciais inválidas, exibir mensagem de erro
          alert('Usuário ou senha inválidos.');
        } else {
          // Erro inesperado, exibir mensagem genérica
          alert('Ocorreu um erro no login.');
        }
      });
  };

  const titleColor = useColorModeValue("cyan.300", "#5bdfe4");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Bem vindo
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Realize o login para acessar ao engajamentor
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                borderRadius="15px"
                mb="24px"
                fontSize="sm"
                type="text"
                placeholder="Seu email"
                size="lg"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Senha
              </FormLabel>
              <Input
                borderRadius="15px"
                mb="36px"
                fontSize="sm"
                type="password"
                placeholder="Sua Senha"
                size="lg"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <FormControl display="flex" alignItems="center">
                <Switch id="remember-login" colorScheme="teal" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  ms="1"
                  fontWeight="normal"
                >
                  Lembrar de mim
                </FormLabel>
              </FormControl>
              <Button
                fontSize="sm"
                type="submit"
                bg="#5bdfe4"
                w="100%"
                h="45"
                mb="20px"
                color="black"
                mt="20px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={handleLogin}
              >
                ACESSAR
              </Button>
            </FormControl>
            {/* <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Não possuí uma conta?
                <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                  A
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={cover}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
