/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import
// To be changed
// import Tables from "views/Dashboard/Tables.js";
import { SettingsIcon } from "components/Icons/Icons";
import {
  CartIcon,
  DocumentIcon,
  HomeIcon,
  PersonIcon,
  StatsIcon,
} from "components/Icons/Icons";
import { IconBase } from "react-icons";
import UsersList from "views/Authentication";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import Default from "views/Dashboard/Default";
import OrderDetails from "views/Ecommerce/Orders/OrderDetails/index";
import OrderList from "views/Ecommerce/Orders/OrderList/index";
import EditProduct from "views/Ecommerce/Products/EditProduct/index";
import NewProduct from "views/Ecommerce/Products/NewProduct/index";
import ProductPage from "views/Ecommerce/Products/ProductPage/index";
import Executor from "views/Executor";
import StoryComponent from "views/Executor/storys";


const dashRoutes = [
  {
    name: "Painel de controle",
    path: "/dashboard",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Visão Geral",
        path: "/dashboard/default",
        component: Default,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Administração",
    category: "pages",
    items: [
      {
        name: "Planos",
        path: "/ecommerce",
        icon: <StatsIcon color="inherit" />,
        collapse: true,

        items: [
          {
            name: "Visão geral",
            path: "/ecommerce/orders/order-list",
            layout: "/admin",
            component: OrderList,
            collapse: false,
            authIcon: <DocumentIcon color="inherit" />,
          },
          {
            name: "Detalhes assinatura",
            path: "/orders",
            collapse: false,
            component: OrderDetails,
            authIcon: <StatsIcon color="inherit" />,
            layout: "/admin",
            
          },
        ],
      },
      {
        name: "Usuários",
        path: "/user",
        icon: <PersonIcon color="inherit" />,
        collapse: true,

        items: [
          {
            name: "Usuarios",
            path: "/user/user-list",
            layout: "/admin",
            component: UsersList,
            collapse: false,
            authIcon: <DocumentIcon color="inherit" />,
          },
        ],
      },
      {
        name: "Executores",
        path: "/executor",
        icon: <SettingsIcon color="inherit" />,
        collapse: true,

        items: [
          {
            name: "Publicações",
            path: "/executor/posts",
            layout: "/admin",
            component: Executor,
            collapse: false,
            authIcon: <DocumentIcon color="inherit" />,
          },
          {
            name: "Storys",
            path: "/executor/storys",
            layout: "/admin",
            component: StoryComponent,
            collapse: false,
            authIcon: <DocumentIcon color="inherit" />,
          },
        ],
      },
    ],
  },
  
  {
    name: "Desenvolvimento",
    category: "pages",
    items: [
      {
        name: "Modelos",
        path: "/pages",
        collapse: true,
        icon: <DocumentIcon color="inherit" />,
        items: [
    
        ],
      },
      {
        name: "Ecommerce",
        path: "/ecommerce",
        icon: <CartIcon color="inherit" />,
        collapse: true,

        items: [
          {
            name: "Products",
            path: "/products",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "New Product",
                component: NewProduct,
                secondaryNavbar: true,
                path: "/ecommerce/products/new-product",
                layout: "/admin",
              },
              {
                name: "Edit Product",
                component: EditProduct,
                path: "/ecommerce/products/edit-product",
                layout: "/admin",
              },
              {
                name: "Product Page",
                component: ProductPage,
                path: "/ecommerce/products/product-page",
                layout: "/admin",
              },
            ],
          },
          {
            name: "Orders",
            path: "/orders",
            collapse: true,
            authIcon: <StatsIcon color="inherit" />,
            items: [
              {
                name: "Order List",
                component: OrderList,
                path: "/ecommerce/orders/order-list",
                layout: "/admin",
              },
              {
                name: "Order Details",
                component: OrderDetails,
                path: "/ecommerce/orders/order-details",
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        name: "Autenticação",
        path: "/authentication",
        icon: <PersonIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Sign In",
            path: "/authentication/sign-in",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignInBasic,
                path: "/authentication/sign-in/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignInCover,
                path: "/authentication/sign-in/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignInIllustration,
                path: "/authentication/sign-in/illustration",
                layout: "/auth",
              },
            ],
          },
          {
            name: "Sign Up",
            path: "/authentication/sign-up",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignUpBasic,
                path: "/authentication/sign-up/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignUpCover,
                path: "/authentication/sign-up/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignUpIllustration,
                path: "/authentication/sign-up/illustration",
                layout: "/auth",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default dashRoutes;
